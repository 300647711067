document.addEventListener('alpine:init', () => {
    Alpine.data('PegasusMediaBlock', () => ({
        videoType: 'upload',
        video: null,

        init() {
            this.video = this.$root.querySelector('video') || this.$root.querySelector('iframe');

            if(!this.video){
                return;
            }

            // Assign the embed video type if needed
            if(this.video.classList.contains('video-embed')){
                this.videoType = 'embed';
            }
        },

         /**
         * playVideo
         * 
         * Description: Hides the placeholder and plays the video.
         * 
         */
         playVideo() {

            // Standard HTML5 videos can simply use the play() and pause() methods.
            if(this.videoType === 'upload') {
                this.video.play();
                this.$refs.videoPlaceholder.classList.add('hidden');
                return;
            }

            // Embed videos need to update the autoplay parameter in the URL.
            if(this.videoType === 'embed') {
                const currentSrc = this.video.src;

                const currentSrcArray = currentSrc.split('&');

                let newSrc = '';

                currentSrcArray.forEach(part => {
                    if(part.includes('autoplay=')){
                        part = 'autoplay=1';
                    }
                    newSrc += part + '&';
                })

                this.video.src = newSrc;
            }

            this.$refs.videoPlaceholder.classList.add('hidden');

            
        },

        setVideoMinHeight(el, iframe = false){
            el.style.minHeight = this.getBoundingRect(el, iframe).width * 0.5625 + 'px';
        },

        /**
         *  getBoundingRect
         *
         *  Gets the size and position of the list box even when it is hidden.
         *
         *  @since    2.0.0
         *
         *  @param element  The select box element
         */
        getBoundingRect(element, iframe) {
            const newElem = element.cloneNode(true);
            let video;
            if(iframe) {
                video = newElem.querySelector('iframe');
            } else {
                video = newElem.querySelector('video');
            }
            newElem.style.display = "block";
            video.style.position = "relative";
            newElem.style.visibility = "hidden";
            element.parentElement.appendChild(newElem);
            let rect = newElem.getBoundingClientRect();
            newElem.remove();
            return rect;
        },
    }))
});