import Flickity from "flickity";

export default class BasePostGridSlider
{
    autoplay = false;
    infinite = false;
    slider = null;
    sliderContainer = null;
    videoPlaying = true;
    selectedIndex = 0;
    loading = true;

    constructor(autoplay, infinite) {
        this.autoplay = autoplay || false;
        this.infinite = infinite || false;
    };

    init() {
        this.sliderContainer = this.$refs.slider;
        const slides = this.sliderContainer.querySelectorAll(".slide");

        let options = {
            cellAlign: this.infinite ? "center" : "left",
            contain: true,
            wrapAround: this.infinite,
            prevNextButtons: false,
            pageDots: false,
            draggable: true,
            on: {
                settle: () => {
                    this.updateTabIndexes(slides);
                },
                change: () => {
                    this.selectedIndex = this.slider.selectedIndex;
                },
            },
        };

        if (this.autoplay && this.autoplay > 0) {
            options.autoPlay = this.autoplay * 1000;
        }

        this.slider = new Flickity(this.sliderContainer, options);

        this.updateTabIndexes(slides);
    };

    /**
     * updateTabIndexes
     *
     * Description: Updates the tabindexes of the slides this is required for accessibility.
     * Inactive slides shouldn't be accessible via the keyboard.
     *
     * @param {} slides
     * @return void
     */
    updateTabIndexes(slides) {
        slides.forEach((slide) => {
            const slideElements = slide.querySelectorAll("a, button");

            slideElements.forEach((element) => {
                if (slide.classList.contains("is-selected")) {
                    element.removeAttribute("tabindex");
                } else {
                    element.setAttribute("tabindex", "-1");
                }
            });
        });
    };
}

document.addEventListener("alpine:init", () => {
    Alpine.data("postGridSlider", (autoplay, infinite) => new BasePostGridSlider(autoplay, infinite));
});
