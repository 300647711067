document.addEventListener('alpine:init', () => {
    Alpine.data('videoMediaPlayer', () => ({
        showPIP: false,
        playing: false,
        videoMediaPlayer: null,
        video: null,
        leftMargin: 0,
        init() {
            this.videoMediaPlayer = this.$refs.videoMediaPlayer;
            this.video = this.$refs.videoMediaPlayer?.querySelector('video');

            this.initVideo();
            this.initIntersectionObserverbserver();
        },
        initVideo: function() {
            if (!this.video) return;

            if (this.$refs.playButton) {
                this.$refs.playButton.addEventListener('click', () => {
                    this.video.play();
                });
            }

            this.video.addEventListener('playing', () => {
                this.playing = true;
            });

            this.video.addEventListener('pause', () => {
                this.playing = false;
            });
        },
        initIntersectionObserverbserver: function() {
            const observer = new IntersectionObserver(([entry]) => {
                this.showPIP = !entry.isIntersecting && entry.boundingClientRect.top < 0 && this.playing;
            }, {
                threshold: 0.5
            });

            observer.observe(this.videoMediaPlayer);
        },
    }));
});
