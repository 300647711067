document.addEventListener('alpine:init', () => {
    Alpine.data('largeMediaBanner', autoplay => ({
        videoType: 'upload',
        videoPlaying: false,
        video: null,
        inView: true,  // Assuming inView should be true initially or set this based on your requirement
        parallaxContainer: null,

        init() {
            this.video = this.$root.querySelector('video.media-video') || this.$root.querySelector('iframe.media-video');

            this.eventListeners();

            if(!this.video){
                return;
            }

            this.videoPlaying = autoplay;

            // Assign the embed video type if needed
            if(this.video.classList.contains('video-embed')){
                this.videoType = 'embed';
            }
        },

        /**
         * togglePlayState
         * 
         * Description: Toggles the play state of the video when the button is pressed.
         * 
         */
        togglePlayState() {

            if(typeof this.$refs.videoPlaceholder === 'undefined' && this.$refs.videoPlaceholder){
                this.$refs.videoPlaceholder.classList.add('hidden');
            }

            // Standard HTML5 videos can simply use the play() and pause() methods.
            if(this.videoType === 'upload') {
                if(this.videoPlaying) {
                    this.video.pause();
                    this.videoPlaying = false;
                } else {
                    this.video.play();
                    this.videoPlaying = true;
                }

                return;
            }

            // Embed videos need to update the autoplay parameter in the URL.
            if(this.videoType === 'embed') {
                const currentSrc = this.video.src;

                const currentSrcArray = currentSrc.split('&');

                let newSrc = '';

                currentSrcArray.forEach(part => {
                    if(part.includes('autoplay=')){
                        if(this.videoPlaying) {
                            part = 'autoplay=0';
                            this.videoPlaying = false;
                        } else {
                            part = 'autoplay=1';
                            this.videoPlaying = true;
                        }
                    }
                    newSrc += part + '&';
                })

                this.video.src = newSrc;
            }

            
        },

        eventListeners() {
            this.parallaxContainer = this.$el.querySelector('.parallax-background');

            console.log(this.parallaxContainer);

            if (!this.parallaxContainer || window.innerWidth < 768) {
                return;
            }

            const parallaxBackgroundHandler = () => {
                this.parallaxBackground();
            };

            if (!this.inView) {
                window.removeEventListener('scroll', parallaxBackgroundHandler);
                return;
            }

            window.addEventListener('scroll', parallaxBackgroundHandler);
        },

        parallaxBackground() {
            const parallaxImg = this.parallaxContainer.querySelector('.image-container');

            if (!parallaxImg) {
                return;
            }

            const parallaxImgTop = parallaxImg.getBoundingClientRect().top - parallaxImg.getBoundingClientRect().height;

            let parallaxOffset = (parallaxImgTop) * 0.1;

            console.log(parallaxOffset);

            parallaxImg.style.transform = `translateY(${parallaxOffset}px)`;
        }
    }))
});