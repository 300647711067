document.addEventListener('alpine:init', () => {
    Alpine.data('navigation', () => ({
        scrolled: false,
        visible: true,
        mobMenuOpen: false,
        lastScrollTop: 0,
        open: null,
        submenu: null,
        megamenu: null,
        currentItem: null,
        isMobile: true,
        loaded: false,
        hasTransparentBg: false,
        showNav: false,
        menuOpen: false,
        hoveredItem: null,
        showSearch: false,
        init() {
            pegasus.onResizeFinished(() => {
                this.isMobile = window.outerWidth <= 1200;
            }, true);

            this.hasTransparentBg = this.$refs.navBar.classList.contains(
                "bg-navbar-transparent"
            );

            window.addEventListener("shownav", (e) => {
                this.showNav = true;
            });

            this.setHeaderHeightVar();

            window.addEventListener('resize', () => {
                this.setHeaderHeightVar();
            });

            window.addEventListener('load', () => {
                this.setHeaderHeightVar();
            });
        },
        lockScroll() {
            if (this.$refs.navBar.getBoundingClientRect().top > 0) {
                window.scrollBy({
                    top: this.$refs.navBar.getBoundingClientRect().top,
                    behavior: 'smooth',
                });
            }

            document.getElementsByTagName("body")[0].style.overflow = "hidden";
        },
        unlockScroll() {
            document.getElementsByTagName("body")[0].style.overflow = "auto";
        },
        menuScrolling() {
            const pageContent = document.getElementById("page-content");
            const navigationContainer = document.getElementById("page-nav-container"),
            navigationHeight = navigationContainer.offsetHeight;
            const page = document.getElementById("content");
            this.scrolled = pageContent.getBoundingClientRect().top <= -navigationHeight;

            let st = window.scrollY;

            if (st >= this.lastScrollTop) {
                if (this.hasTransparentBg === false) {
                    navigationContainer.style.height = navigationHeight + "px";
                }
            } else {
                //Scroll Up
                this.visible = true;
                if (this.hasTransparentBg === false && st === 0) {
                    navigationContainer.style.height = "";
                }
            }

            this.lastScrollTop = st;

            this.hasTransparentBg = this.$refs.navBar.classList.contains(
                "bg-navbar-transparent"
            );
        },
        toggleOpen(menuID) {
            this.sub_nav = [];
            if (this.open !== menuID) {
                this.open = menuID;
            } else {
                this.open = null;
            }
        },
        onClickShowSearch() {
            this.showSearch = true;

            window.requestAnimationFrame(() => {
                this.$refs?.searchField?.focus();
            }, 1);
        },
        setHeaderHeightVar() {
            const navigationContainer = document.getElementById("page-nav-container");

            document.documentElement.style.setProperty('--site-header-height', `${navigationContainer.offsetHeight}px`);
        }
    }));
});