document.addEventListener('alpine:init', () => {
    Alpine.data('parallaxContainer', () => ({
        inView: true,  // Assuming inView should be true initially or set this based on your requirement
        parallaxContainer: null,

        init() {
            this.eventListeners();
        },

        eventListeners() {
            this.parallaxContainer = this.$el.querySelector('.parallax-background');

            console.log(this.parallaxContainer);

            if (!this.parallaxContainer || window.innerWidth < 768) {
                return;
            }

            const parallaxBackgroundHandler = () => {
                this.parallaxBackground();
            };

            if (!this.inView) {
                window.removeEventListener('scroll', parallaxBackgroundHandler);
                return;
            }

            window.addEventListener('scroll', parallaxBackgroundHandler);
        },

        parallaxBackground() {
            const parallaxImg = this.parallaxContainer.querySelector('.image-container');

            if (!parallaxImg) {
                return;
            }

            const parallaxImgTop = parallaxImg.getBoundingClientRect().top - parallaxImg.getBoundingClientRect().height;

            let parallaxOffset = (parallaxImgTop) * 0.1;

            console.log(parallaxOffset);

            parallaxImg.style.transform = `translateY(${parallaxOffset}px)`;
        }
    }))
});
