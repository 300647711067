import pegasus from "../../assets/scripts/global/01-pegasus";

/**
 * allModals
 *
 * Component code for creating all modals on the page
 *
 * 	Usage:
 * 	Add link with the attr 'data-pegasus-modal'.
 * 	Any values passed to the attr will be added as classes on the modal so it can be styled accordingly.
 * 	To include modal navigation, pass the class 'Modal--with-nav' to the data attr. This class will be picked up and the nav will be built.
 *
 * 	Example:
 *  <a href="#" data-pegasus-modal="Modal--team Modal--with-nav"></a>
 *
 * @since  2.0.0
 */
window.allModals = () => {
    return {
        init() {
            const _this = this;
            const modalSkeleton = pegasus.stringToHTML(
                document.getElementById("Modal__Skeleton").innerHTML
            );
            const modalLinks = _this.createModalLinks(
                document.querySelectorAll("[data-pegasus-modal]")
            );

            modalLinks.forEach((link, index) => {
                const modal = modalSkeleton.cloneNode(true);

                console.log(modalLinks); 

                let cssClass = "Modal",
                    modalNav;

                //check for classes to add
                if (link.dataset.pegasusModal.length) {
                    cssClass += " " + link.dataset.pegasusModal;

                    //if nav specified
                    if (link.dataset.pegasusModal.includes("Modal--with-nav")) {
                        modalNav = _this.buildModalNav(index, modalLinks);

                        if (modalNav) {
                            modal
                                .querySelector(".Modal__navigation")
                                .append(modalNav);
                        }
                    }
                }

                //setup classes and id
                modal.className = cssClass;
                modal.id = link.dataset.pegasusModalId;

                //append modal template to page if it does nt already exist
                if (!document.getElementById(link.dataset.pegasusModalId)) {
                    document.body.append(modal);
                }

                //if 4 or less, load modal straight away. More than 4 and an event will be attached in createModalLinks()
                if (modalLinks.length <= 4) {
                    setTimeout(function () {
                        _this.loadModalContent(
                            link.href,
                            link.dataset.pegasusModalId
                        );
                    }, 1000);
                }
            });

            window.modal = this;
        },

        doesLinkAlreadyExist(link, linksArray) {
            return linksArray.find((element) => element.href === link.href);
        },

        createModalLinks(links) {
            const _this = this;
            const modalLinks = [];

            if (links.length) {
                links.forEach((link, index) => {
                    // Add ?modal=yes query string to url, so head and foot are removed from fetched page
                    const href = new URL(link.href);
                    href.search = "?modal=yes";
                    link.href = href;

                    const existingLink = _this.doesLinkAlreadyExist(
                        link,
                        modalLinks
                    );

                    //check for existing link and copy id if true
                    if (existingLink) {
                        link.setAttribute(
                            "data-pegasus-modal-id",
                            existingLink.dataset.pegasusModalId
                        );
                    } else {
                        link.setAttribute(
                            "data-pegasus-modal-id",
                            `modal-${index}`
                        );
                    }

                    //if there are 5 or more modals, add 'click' to load more
                    if (links.length > 4) {
                        link.addEventListener("click", (e) => {
                            e.preventDefault();
                            _this.loadModalContent(
                                link.href,
                                link.dataset.pegasusModalId
                            );
                        });
                    }

                    //add event to emit 'modalopen' event for specified modal
                    link.addEventListener("click", (e) => {
                        _this.emitModalOpen(e);
                    });

                    //check we're not adding existing links.
                    if (!existingLink) modalLinks.push(link);
                });
            }

            return modalLinks;
        },

        emitModalOpen(e) {
            e.preventDefault();

            const modalID = e.currentTarget.dataset.pegasusModalId;
            const myEvent = new CustomEvent("modalopen", {
                detail: { modalID },
            });

            window.dispatchEvent(myEvent);
        },

        buildModalNav(currentIndex, allLinksArray) {
            const _this = this;

            let prevLinkHTML, nextLinkHTML;

            //check if we're at the start or end
            let prevLink =
                currentIndex > 0 ? allLinksArray[currentIndex - 1] : "";
            let nextLink =
                currentIndex < allLinksArray.length - 1
                    ? allLinksArray[currentIndex + 1]
                    : "";

            //check if links are modals with navs
            if (prevLink !== "")
                prevLink = prevLink.dataset.pegasusModal.includes(
                    "Modal--with-nav"
                )
                    ? prevLink
                    : "";
            if (nextLink !== "")
                nextLink = nextLink.dataset.pegasusModal.includes(
                    "Modal--with-nav"
                )
                    ? nextLink
                    : "";

            if (prevLink !== "") {
                const target = prevLink.dataset.pegasusModalId,
                    href = new URL(prevLink.href);

                href.search = "?modal=yes";

                prevLinkHTML = `<a href="${href}" class="prev-link arrow-btn" data-pegasus-modal-id="${target}"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                <g clip-path="url(#clip0_582_359)">
                  <path d="M16.8828 10.5L3.75781 10.5" stroke="#0D1418" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M8.75781 16.125L3.13281 10.5L8.75781 4.875" stroke="#0D1418" stroke-linecap="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_582_359">
                    <rect width="20" height="20" fill="white" transform="translate(0.0078125 0.5)"/>
                  </clipPath>
                </defs>
              </svg></a>`;
            } else {
                prevLinkHTML = `<span class="prev-link arrow-btn -disabled"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                <g clip-path="url(#clip0_582_359)">
                  <path d="M16.8828 10.5L3.75781 10.5" stroke="#0D1418" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M8.75781 16.125L3.13281 10.5L8.75781 4.875" stroke="#0D1418" stroke-linecap="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_582_359">
                    <rect width="20" height="20" fill="white" transform="translate(0.0078125 0.5)"/>
                  </clipPath>
                </defs>
              </svg></span>`;
            }

            if (nextLink !== "") {
                const target = nextLink.dataset.pegasusModalId,
                    href = new URL(nextLink.href);

                href.search = "?modal=yes";

                nextLinkHTML = `<a href="${href}" class="next-link arrow-btn" data-pegasus-modal-id="${target}"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                <g clip-path="url(#clip0_582_878)">
                  <path d="M3.13281 10.5L16.2578 10.5" stroke="#0D1418" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M11.2578 16.125L16.8828 10.5L11.2578 4.875" stroke="#0D1418" stroke-linecap="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_582_878">
                    <rect width="20" height="20" fill="white" transform="matrix(-1 0 0 1 20.0078 0.5)"/>
                  </clipPath>
                </defs>
              </svg></a>`;
            } else {
                nextLinkHTML = `<span class="next-link arrow-btn -disabled"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                <g clip-path="url(#clip0_582_878)">
                  <path d="M3.13281 10.5L16.2578 10.5" stroke="#0D1418" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M11.2578 16.125L16.8828 10.5L11.2578 4.875" stroke="#0D1418" stroke-linecap="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_582_878">
                    <rect width="20" height="20" fill="white" transform="matrix(-1 0 0 1 20.0078 0.5)"/>
                  </clipPath>
                </defs>
              </svg></span>`;
            }

            //create modalNav DOM element
            const modalNav = pegasus.stringToHTML(
                `<div class="links">${prevLinkHTML} ${nextLinkHTML}</div>`
            );

            //set up modal open and ajax events on modal nav links
            modalNav
                .querySelectorAll("[data-pegasus-modal-id]")
                .forEach((link) => {
                    link.addEventListener("click", _this.emitModalOpen);

                    link.addEventListener("click", (e) => {
                        e.preventDefault();
                        _this.loadModalContent(
                            link.href,
                            link.dataset.pegasusModalId
                        );
                    });
                });

            return modalNav;
        },

        loadModalContent(href, modalID) {
            pegasus
                .fetchContent(href)
                .then((resp) => {
                    if (!resp.ok) throw Error(resp.statusText);

                    return resp.text();
                })
                .then((htmlResponse) => {
                    const modalHTML = htmlResponse;

                    const modalBody = document.querySelector(
                        `#${modalID} .Modal__body`
                    );

                    modalBody.innerHTML = modalHTML;
                })
                .then(() => {
                    //reinit anything else here...

                    //reinit cf7 forms
                    if (typeof wpcf7 !== "undefined" && wpcf7 !== null) {
                        const cf7Forms = document.querySelectorAll(
                            `#${modalID} .wpcf7 > form`
                        );
                        if (cf7Forms.length)
                            cf7Forms.forEach((form) => wpcf7.init(form));
                    }
                })
                .catch((error) => {
                    console.error(error);

                    const modalBody = document.querySelector(
                            `#${modalID} .Modal__body`
                        ),
                        errorContainer =
                            modalBody.querySelector(".Modal__error");

                    if (
                        typeof errorContainer === "undefined" ||
                        errorContainer === null
                    )
                        return;

                    errorContainer.classList.remove("hidden");
                })
                .finally(() => {
                    if (document.lazyLoadInstance) {
                        document.lazyLoadInstance.update();
                    }
                });
        },
    };
};

let currentOpenModal = null;

/**
 * singleModal
 *
 * Component code for a single modal
 *
 * @since  2.0.0
 */
window.singleModal = () => {
    return {
        open: false,
    
        handleModalOpen(evt) {
            if (!evt.detail.modalID) return;
            if (this.$el.id !== evt.detail.modalID) return;

            console.log(this.$el);
            
            // Close currently open modal
            if (currentOpenModal && currentOpenModal !== this) {
                currentOpenModal.handleModalClose();
            }

            //only run on actual modals, not skeleton
            if (this.$el.classList.contains("ModalSkeleton")) return;

            document.body.classList.add("modal-open");
            this.open = this.$el.id === evt.detail.modalID;
            currentOpenModal = this;
        },

        handleModalClose() {
            document.body.classList.remove("modal-open");
            this.open = false;
            currentOpenModal = null;
        },
    };
};
