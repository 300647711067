/**
 *  form-elements
 *
 *  Form element interaction
 *
 *  @since    2.0.0
 *
 */

import pegasus from './01-pegasus';

/**
 *  selectField
 *
 *  Convert any select field into a stylable selection list similar to select2 and selectric
 *
 *
 *  @since    2.0.0
 *
 *  @param  elem    The select field
 *  @param  options Additional options
 *  @return mixed   Stylable selection list is created
 */
pegasus.selectField = (elem, options = {}) => {

    function SelectField(elem, options) {
        const _this = this;

        this.select = elem;

        this.defaults = {
            openOnHover: false,
            itemsToShow: 10,
            firstItemDisabled: false,
            columns: 1,
            cancelSelection: false,
            hideSelected: false,
            searchField: false,
            updateButtonText: true,
            keys: {
                previous: [37, 38],                 // Left / Up
                next: [39, 40],                 // Right / Down
                select: [13],                     // Enter
                open: [13, 32, 37, 38, 39, 40], // Enter / Space / Left / Up / Right / Down
                close: [9, 27]                   // Tab / Escape
            },
            classNames: {
                outerWrapper: '',
                selectorWrapper: '',
                selector: 'flex justify-between w-full',
                selectorOpen: '',
                selectionList: 'mt-1 w-full',
                listItem: 'text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9',
                listItemFocus: 'bg-gray-300'
            }
        }

        this.options = pegasus.extend(true, this.defaults, options);

        this.structure = {
            wrapper: '',
            hideSelectWrapper: '',
            newSelectorWrapper: '',
            selectorButton: '',
            listScroll: '',
            listBox: '',
        };


        this.choices = [];
        this.currentChoice = 0;

        this.listOpen = false;

        this.init = {

            /**
             *  setup
             *
             *  Initialise all of the required functions
             *
             *  @since    2.0.0
             *
             */
            setup() {
                this.createWrapper();
                this.buildChoices();
                this.createSelector();
                this.createListBox();
                this.addEventListeners();


                if(typeof _this.select.value !== "undefined" && _this.select.value !== ''){
                   _this.structure.selectorButton.classList.add('-selected');
                } else {
                   _this.structure.selectorButton.classList.remove('-selected');
                }
            },

            /**
             *  createWrapper
             *
             *  Creates the main div wrapper structure that contains the selection list and hides the select field.
             *
             *  @since    2.0.0
             *
             */
            createWrapper() {
                const mainWrapper = document.createElement('div'),
                    hideSelectWrapper = document.createElement('div'),
                    newSelectorWrapper = document.createElement('div');

                mainWrapper.setAttribute('class', 'Pegasus__Select-wrapper relative ' + _this.options.classNames.outerWrapper);
                hideSelectWrapper.setAttribute('class', 'Pegasus__Select-hide-input');
                newSelectorWrapper.setAttribute('class', 'Pegasus__Select-selector ' + _this.options.classNames.selectorWrapper);

                this.wrapElement(_this.select, hideSelectWrapper);
                this.wrapElement(hideSelectWrapper, mainWrapper);
                mainWrapper.appendChild(newSelectorWrapper);

                _this.structure.wrapper = mainWrapper;
                _this.structure.hideSelectWrapper = hideSelectWrapper;
                _this.structure.newSelectorWrapper = newSelectorWrapper;

            },

            /**
             *  createSelector
             *
             *  Creates the button that acts as the main select field and activates the selection list when clicked.
             *
             *  @since    2.0.0
             *
             */
            createSelector() {

               if(_this.options.searchField){
                   this.createSearchField();
                   return;
               }

               let selector = '<button type="button" class="' + _this.options.classNames.selector + '" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">';

               const selectedOptions = _this.select.selectedOptions;
               let image = '';

               if(typeof selectedOptions[0].dataset.pegasusOptionImage !== 'undefined' && selectedOptions[0].dataset.pegasusOptionImage.length){
               image = '<img src="' + selectedOptions[0].dataset.pegasusOptionImage + '" width="18" height="12" alt="' + selectedOptions[0].textContent + '">';
               }
               
               selector += '<span class="Pegasus__Select-selector-label flex items-center">' + image + selectedOptions[0].textContent + '<span class="count"></span></span>';

               if(_this.options.cancelSelection) {
               selector += '<span class="cancel"><i class="icon ion-ios-close"></i></span>'
               }

               selector += '<span class="filter-list block w-full"></span>';

               selector += '</button>';

               const parser = new DOMParser(),
                   htmlSelector = parser.parseFromString(selector, 'text/html');

               const newButton = htmlSelector.body.querySelectorAll('button')[0];
               _this.structure.selectorButton = newButton;
               _this.structure.newSelectorWrapper.appendChild(newButton);
            },

            /**
             *  createSearchField
             *
             *  Creates the text input that acts as the main select field and activates the selection list when clicked.
             *
             *  @since    2.0.0
             *
             */
            createSearchField() {
               const selectedOptions = _this.select.selectedOptions;

               let selector = '<input type="text" class="' + _this.options.classNames.selector + '" value="' + selectedOptions[0].textContent + '" aria-haspopup="listbox">';

               const parser = new DOMParser(),
                   htmlSelector = parser.parseFromString(selector, 'text/html');

                   const inputWrapper = document.createElement('div');

                   inputWrapper.classList.add('input-wrapper');

               const newButton = htmlSelector.body.querySelectorAll('input[type="text"]')[0];
               _this.structure.selectorButton = newButton;
               inputWrapper.appendChild(newButton);
               _this.structure.newSelectorWrapper.appendChild(inputWrapper);
            },

            /**
             *  createListBox
             *
             *  Creates the ul list that contains all of the choices from the select field.
             *
             *  @since    2.0.0
             *
             */
            createListBox() {
                let listScroll = '<div class="Select_Scroll-Box overflow-scroll sm:absolute z-10"></div>';
                const parser = new DOMParser(),
                    listScrollBox = parser.parseFromString(listScroll, 'text/html'),
                    listScroller = listScrollBox.body.querySelectorAll('div')[0];

                _this.structure.listScroll = listScroller;

                _this.structure.newSelectorWrapper.appendChild(listScroller);

                const selectedOptions = _this.select.selectedOptions;

                let listBox = '<ul class="" tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="' + selectedOptions[0].value + '">';

                const listItems = this.getChoices();

                listItems.map(item => {
                   if(item.tagName === 'OPTION') {
                       if((typeof item.attributes.value === 'undefined' || item.attributes.value === '') && _this.options.firstItemDisabled){
                           return;
                       }
                       const id = _this.select.id + '-' + item.attributes.value.replaceAll(/\s+/g, '__');
                       let additionalClasses = '';
   
                       if(typeof item.attributes.disabled !== "undefined"){
                           additionalClasses = " -disabled";
                       }

                       let itemImage = '';

                       const hasImage = typeof item.attributes.datapegasusoptionimage !== "undefined" && 
                                        item.attributes.datapegasusoptionimage !== null;

                       if (hasImage) {
                           itemImage = '<img src="' + item.attributes.datapegasusoptionimage + '" width="18" height="12" alt="' + item.text + '" class="mr-2" />';
                       }

                       listBox +='<li class="' +_this.options.classNames.listItem + " " + additionalClasses + '" role="option" id="' + id + '"><span class="flex items-center ' + (hasImage ? 'has-image' : '') + '">' + itemImage + item.text + "</span></li>";
                   }

                   if(item.tagName === 'OPTGROUP') {
                       let listChildren = '';

                       [...item.children].map(child => {
                           if(typeof child.attributes.value === 'undefined' || child.attributes.value === ''){
                               return;
                           }
                           const id = _this.select.id + '-' + child.attributes.value.replaceAll(/\s+/g, '__');
                           let additionalClasses = '';
       
                           if(typeof child.attributes.disabled !== "undefined"){
                               additionalClasses = " -disabled";
                           }
       
                           listChildren += '<li class="' + _this.options.classNames.listItem + ' ' + additionalClasses + '" role="option" id="' + id + '"><span>' + child.text + '</span></li>';
                       });

                       listBox += '<li class="option-group"><h5>'+ item.attributes.label +'</h5><ul>' + listChildren + '</ul></li>'
                   }

                    
                });

                listBox += '</ul>';


                const itemsParser = new DOMParser(),
                    htmlListBox = itemsParser.parseFromString(listBox, 'text/html');

                if (htmlListBox.body.querySelectorAll('ul')[0].querySelectorAll('li.option-group').length === 1) {
                    htmlListBox.body.querySelectorAll('ul')[0].classList.add('single-column');
                }

                const newList = htmlListBox.body.querySelectorAll('ul')[0];

                _this.structure.listBox = newList;

                _this.structure.listScroll.appendChild(newList);

                _this.structure.listBox.style.display = "none";

                this.setBoxHeight();
            },

            setBoxHeight() {
                const listItems = _this.structure.listBox.querySelectorAll('li');

                if (_this.options.itemsToShow >= listItems.length || window.outerWidth < 768) {
                    return;
                }

                let listHeight = 0;

                [...listItems].map((item, index) => {

                    if (index + 1 <= _this.options.itemsToShow) {
                        let rect = this.getBoundingRect(item);
                        listHeight += (rect.bottom - rect.top);
                    }

                });

                _this.structure.listScroll.style.height = listHeight + 'px';
            },

            /**
             *  buildChoices
             *
             *  Loops through select field <options> to pull out all of the values to use in the list box
             *
             *  @since    2.0.0
             *
             */
            buildChoices() {
                const options = _this.select.children;

                [...options].map(option => {

                   if(_this.options.hideSelected && option.value == _this.select.selectedOptions[0].value){
                       return
                   }

                    let optionObject = {};
                    optionObject.tagName = option.tagName;
                    optionObject.text = this.sanitizeContent(option.text);
                    optionObject.attributes = {};
                    optionObject.children = [];


                    for (let i = 0; i <= option.attributes.length; i++) {
                        const attrib = option.attributes[i];

                        if (typeof attrib !== 'undefined') {
                            optionObject.attributes[attrib.name.replaceAll('-', '')] = attrib.value;
                        }

                    }

                    if(option.hasChildNodes()) {
                       [...option.children].map(childOpt => {
                           let childOptionObject = {};
                           childOptionObject.tagName = childOpt.tagName;
                           childOptionObject.text = this.sanitizeContent(childOpt.text);
                           childOptionObject.attributes = {};

                           for (let i = 0; i <= childOpt.attributes.length; i++) {
                               const attrib = childOpt.attributes[i];
       
                               if (typeof attrib !== 'undefined') {
                                   childOptionObject.attributes[attrib.name] = attrib.value;
                               }
       
                           }

                           optionObject.children.push(childOptionObject);
                       })
                    }

                    _this.choices.push(optionObject);

                });
            },

            /**
             *  getChoices
             *
             *  Simply gets the available choices for the select field.
             *
             *  @since    2.0.0
             *
             *  @return array   this.choices array - All of the choices pulled using this.buildChoices()
             */
            getChoices() {
                return _this.choices;
            },

            /**
             *  sanitizeContent
             *
             *  Make sure the content included in the options tags is safe to insert into the new options list
             *
             *  @since    2.0.0
             *
             *  @return string  simple text string
             */
            sanitizeContent(string) {
                let temp = document.createElement('div');
                temp.textContent = string;
                return temp.innerHTML;
            },

            /**
             *  Wrap Element
             *
             *  Wraps one element inside another
             *
             *  @since    2.0.0
             *
             */
            wrapElement(element, wrapper) {

                if (element.parentNode !== null) {
                    element.parentNode.insertBefore(wrapper, element);
                } else {
                    element.insertBefore(wrapper, element);
                }

                wrapper.appendChild(element);
            },

            selectedValue(value){
                let val = value.replace(_this.select.id+'-', '');
                val = val.replaceAll('__', ' ');
                return val;
            },

            /**
             *  setValue
             *
             *  Sets the value of the select field and triggers a change event.
             *
             *  @since    2.0.0
             *
             *  @param val  The value to apply to the select field
             */
            setValue(val) {
                _this.select.value = this.selectedValue(val) == '0' ? '' : this.selectedValue(val);
                _this.select.dispatchEvent(new Event('change'));

                if(typeof _this.select.value !== "undefined" && _this.select.value !== ''){
                   _this.structure.selectorButton.classList.add('-selected');
                } else {
                   _this.structure.selectorButton.classList.remove('-selected');
                }
            },

            /**
             *  updateButton
             *
             *  Updates the content displayed in the button to reflect the value of the select field.
             *
             *  @since    2.0.0
             *
             *  @param data  The content to replace the button content with
             */
            updateButton(data) {
               
               if(_this.options.searchField){
                   [...data].map(item => {


                       _this.structure.selectorButton.value = item.textContent;
                       
                       
                    });
                   
                   return;
               }

               const buttonLabel = _this.structure.selectorButton.querySelector('.Pegasus__Select-selector-label');

               //  [...buttonLabel].map(item => {
               //      _this.structure.selectorButton.removeChild(item);
               //  });

               buttonLabel.textContent = "";

                [...data].map(item => {


                   if(typeof item.classList !== "undefined" && item.classList.contains('has-image')) {
                       buttonLabel.innerHTML = item.innerHTML;
                   } else {
                       if(typeof item.textContent !== "undefined"){
                           buttonLabel.textContent = item.textContent;
                       } else {
                           buttonLabel.textContent = item;
                       }
                   }
                   
                   
                });
            },

            /**
             *  addEventListeners
             *
             *  Set all the event listeners for the select field
             *
             *  @since    2.0.0
             *
             */
            addEventListeners() {
                _this.structure.selectorButton.addEventListener('click', () => {
                   this.toggleList();
                   //this.setListPosition();
                    
                }, false);

                if (_this.options.openOnHover) {
                    _this.structure.wrapper.addEventListener('mouseenter', () => {
                        this.showList();
                    }, false);
                    _this.structure.wrapper.addEventListener('mouseleave', () => {
                        this.hideList();
                    }, false);
                }

                document.body.addEventListener('click', e => {
                    if (e.target !== _this.structure.wrapper && !_this.structure.wrapper.contains(e.target)) {
                        this.hideList();
                    }
                }, false);

                let clickableChildren = [];

                [..._this.structure.listBox.children].map(elem => {
                   if(!elem.classList.contains('option-group') && !elem.classList.contains('-disabled')){
                       clickableChildren.push(elem);
                   }

                   if(elem.hasChildNodes()){
                       const childElements = elem.querySelectorAll('li');
                       childElements.forEach(child => {
                           if(!child.classList.contains('option-group') && !child.classList.contains('-disabled')){
                               clickableChildren.push(child);
                           }
                       })
                   }
                   
                });


                [...clickableChildren].map(elem => {
                    let classes = [];

                    if(_this.options.classNames.listItemFocus.length) {
                       classes = _this.options.classNames.listItemFocus.split(' ');
                    }

                    classes.push('-focused');

                    elem.addEventListener('click', e => {
                        this.setValue(e.currentTarget.id);
                    }, false);

                    elem.addEventListener('mouseenter', e => {
                        e.currentTarget.classList.add(...classes);
                    }, false);

                    elem.addEventListener('mouseleave', e => {
                        e.currentTarget.classList.remove(...classes);
                    }, false);
                });

                _this.select.addEventListener('change', e => {
                   const allListItems = _this.structure.listBox.querySelectorAll('li');
                    [...allListItems].map(item => {
                        if (this.selectedValue(item.id) === e.target.value) {
                            if(_this.options.updateButtonText){
                                this.updateButton(item.children);
                            }
                        } else if (e.target.value === ''){
                           const emptyValue = _this.select.querySelector('option[value=""]');
                           if(typeof emptyValue !== "undefined" && emptyValue !== null){
                                if(_this.options.updateButtonText){
                                    this.updateButton([emptyValue.textContent])
                                }
                           }

                        }
                    });
                }, false);

                _this.select.addEventListener('focus', () => {
                    _this.structure.selectorButton.focus();
                }, false);


                window.addEventListener('scroll', () => {
                    if (_this.listOpen) {
                        //this.setListPosition();
                    }
                }, false);

                window.addEventListener('resize', () => {
                    if (_this.listOpen) {
                        //this.setListPosition();
                    }
                }, false);

                document.addEventListener('keydown', e => {
                    this.handleKeys(e);
                }, false);

                const cancel = _this.structure.selectorButton.querySelector('.cancel');

                if(cancel !== null){
                   cancel.addEventListener('click', e => {
                       this.setValue('');

                    })
                }

                if(_this.options.searchField){
                   _this.structure.selectorButton.addEventListener('keyup', e => {
                       const searchValue = e.target.value.toUpperCase();

                       console.log(searchValue);

                       [...clickableChildren].map(elem => {
                           const currentValue = elem.textContent.toUpperCase();

                           if(currentValue.indexOf(searchValue) === -1){
                               elem.style.display = 'none';
                           } else {
                               elem.style.display = 'block';
                           }
                       });
                   })
                }

            },

            /**
             *  handleKeys
             *
             *  Handles all interaction that happens with the keyboard.
             *
             *  @since    2.0.0
             *
             *  @param e  The current event
             */
            handleKeys(e) {

                if (document.activeElement !== _this.structure.selectorButton) {
                    return;
                }

                if(!_this.options.searchField) {
                   e.preventDefault();
                }
               

                let key = e.which;
                let keys = _this.options.keys;

                let isPrevKey = keys.previous.includes(key),
                    isNextKey = keys.next.includes(key),
                    isSelectKey = keys.select.includes(key),
                    isOpenKey = keys.open.includes(key),
                    isCloseKey = keys.close.includes(key);

                if (isOpenKey) {
                    if (!_this.listOpen) {
                        this.showList();
                    }
                }

                if (isCloseKey) {
                    if (_this.listOpen) {
                        this.hideList();
                    }
                }

                if (_this.listOpen) {
                    if (isNextKey || isPrevKey) {
                        let currentItem = _this.structure.listBox.querySelectorAll('li.-focused')[0];

                        if (typeof currentItem !== 'undefined') {
                            let nextSelection = isNextKey ? currentItem.nextSibling : currentItem.previousSibling;
                            if (nextSelection !== null) {
                                nextSelection.dispatchEvent(new Event('mouseenter'));
                            } else {
                                _this.structure.listBox.querySelectorAll('li')[0].dispatchEvent(new Event('mouseenter'));
                            }
                            currentItem.dispatchEvent(new Event('mouseleave'));
                        } else {
                            _this.structure.listBox.querySelectorAll('li')[0].dispatchEvent(new Event('mouseenter'));
                        }
                    }


                    if (isSelectKey) {
                        this.setValue(_this.structure.listBox.querySelectorAll('li.-focused')[0].id);
                        this.hideList(false);
                    }
                }

            },

            /**
             *  setListPosition
             *
             *  Handles where the list should appear depending on where the select field is in
             *  relation to the screens edge.
             *
             *  @since    2.0.0
             *
             */
            setListPosition() {
                let rect = _this.structure.listScroll.getBoundingClientRect();

                _this.structure.listScroll.classList.remove('top-0', 'transform', '-translate-y-full');
                   _this.structure.listScroll.classList.add('bottom-0', 'transform', 'translate-y-full');

                const windowTop = window.scrollY,
                    windowBottom = window.innerHeight,
                    listTop = rect.top,
                    listBottom = rect.top + rect.height;


               if (listBottom > windowBottom) {
                   _this.structure.listScroll.classList.add('top-0', 'transform', '-translate-y-full');
                   _this.structure.listScroll.classList.remove('bottom-0', 'translate-y-full');
               } 

               if (listTop <= 90) {
                   _this.structure.listScroll.classList.remove('top-0', 'transform', '-translate-y-full');
                   _this.structure.listScroll.classList.add('bottom-0', 'transform', 'translate-y-full');
               }


            },

            /**
             *  getBoundingRect
             *
             *  Gets the size and position of the list box even when it is hidden.
             *
             *  @since    2.0.0
             *
             *  @param element  The select box element
             */
            getBoundingRect(element) {
                const newElem = element.cloneNode(true);
                newElem.style.display = "block";
                newElem.style.visibility = "hidden";
                document.body.appendChild(newElem);
                let rect = newElem.getBoundingClientRect();
                document.body.removeChild(newElem);
                return rect;
            },

            /**
             *  toggleList
             *
             *  Opens and closes the select box
             *
             *  @since    2.0.0
             *
             */
            toggleList() {
                if (_this.structure.listBox.offsetParent === null) {
                    this.showList();
                    if(_this.options.searchField) {
                       _this.structure.selectorButton.value = '';
                    }
                } else {
                   if(!_this.options.searchField) {
                       this.hideList();
                   }
                    
                }
            },

            /**
             *  showList
             *
             *  Displays the select list options
             *
             *  @since    2.0.0
             *
             *  @param trigger  A flag to decide whether this function should also dispatch the associated events.
             */
            showList(trigger = true) {
                _this.structure.listBox.style.display = 'block';
                _this.structure.selectorButton.classList.add(..._this.options.classNames.selectorOpen.split(' '));
                _this.structure.listScroll.classList.add(..._this.options.classNames.selectionList.split(' '));
                if (trigger) {
                    _this.structure.wrapper.classList.add('Pegasus__Select-open');
                    _this.select.dispatchEvent(new Event('pegasus-select-open'));

                    setTimeout(() => {
                        _this.listOpen = true;
                    }, 10);
                }
                return true;
            },

            /**
             *  hideList
             *
             *  Hides the select list options
             *
             *  @since    2.0.0
             *
             *  @param trigger  A flag to decide whether this function should also dispatch the associated events.
             */
            hideList(trigger = true) {
                _this.structure.listBox.style.display = 'none';
                _this.structure.selectorButton.classList.remove(..._this.options.classNames.selectorOpen.split(' '));
                _this.structure.listScroll.classList.remove(..._this.options.classNames.selectionList.split(' '));
                _this.structure.wrapper.classList.remove('Pegasus__Select-open');
                if (trigger) {
                    _this.listOpen = false;
                    _this.select.dispatchEvent(new Event('pegasus-select-close'));
                }

                if(_this.options.searchField) {
                   if(_this.select.value === '') {
                       const selections = _this.structure.listBox.children;
                       
                       _this.structure.selectorButton.value = selections[0].textContent;
                   } else {
                       const selectedItem = _this.structure.listBox.querySelector('#' + _this.select.id + '-' + _this.select.value.replaceAll(/\s+/g, '__'));
                       _this.structure.selectorButton.value = selectedItem.textContent;
                   }
   
                }
                
                return true;
            },

        }

        return this.init.setup();
    }

    if(HTMLCollection.prototype.isPrototypeOf(elem) || NodeList.prototype.isPrototypeOf(elem)) {
        [...elem].map(select => {
            return new SelectField(select, options);
        });
    } else {
        return new SelectField(elem, options);
    }
    

}

document.addEventListener('DOMContentLoaded', function () {
    console.log('DOM fully loaded and parsed');

    var forms = document.querySelectorAll('.wpforms-form');

    forms.forEach(function (form) {
        form.addEventListener('submit', function (event) {
            event.preventDefault();
            console.log('Form submit intercepted');

            // Allow WPForms to perform validation by waiting for a short delay
            setTimeout(function () {
                var errors = form.querySelectorAll('.wpforms-error');
                if (errors.length > 0) {
                    console.log('Validation errors found:', errors);
                    alert('Please correct the form errors and try again.');
                    return; // Do not proceed with the submission
                }

                try {
                    // Proceed with the custom submission if no validation errors
                    var formData = new FormData(form);
                    var jsonObject = {
                        checkListId: 1,
                        contact: {
                            id: 0,
                            guid: "",
                            prefix: "",
                            firstName: "",
                            lastName: "",
                            emailAddress: "",
                        },
                        customFields: [],
                        messageBody: ""
                    };

                    // To handle multiple values for customFields
                    var interests = [];
                    var agreements = [];

                    formData.forEach((value, key) => {
                        console.log('Processing field:', key, value);
                        if (key === "wpforms[fields][3]") {
                            jsonObject.contact.firstName = value;
                        } else if (key === "wpforms[fields][2]") {
                            jsonObject.contact.lastName = value;
                        } else if (key === "wpforms[fields][5]") {
                            jsonObject.contact.emailAddress = value;
                        } else if (key === "wpforms[fields][8][]") {
                            interests.push(value);
                        } else if (key === "wpforms[fields][10][]") {
                            agreements.push(value);
                        } else if (key === "wpforms[fields][14]") {
                            jsonObject.messageBody = value;
                        } else if (key === "wpforms[id]") {
                            jsonObject.contact.id = parseInt(value); // Ensure ID is an integer
                        }
                    });

                    // Add interests and agreements to customFields
                    if (interests.length > 0) {
                        jsonObject.customFields.push({ name: "Interest", value: interests.join(", ") });
                    }

                    if (agreements.length > 0) {
                        jsonObject.customFields.push({ name: "Agreement", value: agreements.join(", ") });
                    }

                    // Ensure messageBody is not empty
                    if (!jsonObject.messageBody) {
                        jsonObject.messageBody = "No message provided."; // Provide a default message if necessary
                    }

                    console.log('Constructed JSON object:', JSON.stringify(jsonObject, null, 2));

                    // Submit the form data to the API endpoint
                    fetch('https://api.engage-powered.com/v1/Checklists', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json-patch+json',
                            'X-API-Key': 'dc449ce6-8be2-4029-8ff0-016bce8a6075'
                        },
                        body: JSON.stringify(jsonObject)
                    })
                    .then(response => {
                        if (!response.ok) {
                            return response.json().then(data => {
                                console.error('API response error:', data);
                                throw new Error(data.message || 'Unknown error');
                            });
                        }
                        return response.json();
                    })
                    .then(data => {
                        console.log('Form data submitted successfully:', data);
                        // Optionally reset the form
                        form.reset();
                    })
                    .catch(error => {
                        console.error('API response error:', error);
                    });
                } catch (error) {
                    console.error('Error processing form data:', error);
                }
            }, 500); // Adjust the delay as needed
        });
    });
});


export default pegasus;