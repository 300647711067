document.addEventListener('alpine:init', () => {
    Alpine.data('postGrid', () => ({
        isLoading: false,   

        /**
         * loadMore
         * 
         * Description: Loads the next page of posts below the current set of posts.
         * @return void
         * 
         */   
        loadMore(){

            // Get the post grid container and the data selector for the post grid.
            const postGrid = this.$root.querySelector('[data-pegasus-filterable]');
            const dataSelector = '[data-pegasus-filterable="'+postGrid.dataset.pegasusFilterable+'"]';
        
            // Get the url for the next page of posts. This is taken from the href of the load more button.
            const href = this.$el.getAttribute("href");

            // Get the container and elements to be loaded from the next page.
            const container = [dataSelector];
            const elements = ['[data-pegasus-single-card]', '.Post__Grid-next-page'];

            // Only allow the load more button to be clicked whilst we're not waiting for a response.
            if(!this.isLoading){
                this.isLoading = true;

                // Run the loadMoreContent function from pegasus.
                pegasus.loadMoreContent(href, container, elements, {}, () => {
                    this.isLoading = false;

                    // Remove the load more button from the original page, 
                    // keeping only the one that comes back from the request.
                    this.$el.parentElement.remove();
                });
            }

        },

         /**
         * fetchPage
         * 
         * Description: Gets the requested page of posts based on the 
         * link selected in the pagination.
         * 
         * @return void
         */
         fetchPage(href = null){
            // Get the post grid container and the data selector for the post grid.
            const postGrid = this.$root.querySelector('[data-pegasus-filterable]');
            const elements = ['data-pegasus-filterable="'+postGrid.dataset.pegasusFilterable+'"'];

            // Only allow a request to happen whilst we're not waiting for a response.
            if(!this.isLoading){
                this.isLoading = true;

                // Run the ajaxContent function from pegasus.
                pegasus.ajaxContent(href, elements, {}, () => {
                    this.isLoading = false;

                    // Update the url with the current filters.
                    history.pushState('', '', href);

                    pegasus.scroll(postGrid);

                    // If the post grid items open in modals make sure to re-initialise them.
                    if(typeof window.modal !== "undefined"){
                        window.modal.init();
                    }

                });
            }
        },

    }))
});