import BasePostGridSlider from "../../vendor/plug-and-play-design/pegasus-one-post-grid-slider/src/script";
import Flickity from 'flickity';

class PostGridSlider extends BasePostGridSlider {
    constructor(autoplay, infinite) {
        super(autoplay, infinite);
    }

    totalWidth = 0;
    slideXTotal = 0;
    isMobile = window.innerWidth <= 768; // Define mobile threshold

    init() {
        this.sliderContainer = this.$refs.slider;

        if (this.isMobile) {
            this.destroySlider();
            return;
        }

        let options = {
            cellAlign: this.infinite ? "center" : "left",
            contain: true,
            wrapAround: this.infinite,
            prevNextButtons: false,
            pageDots: false,
            draggable: true,
            on: {
                settle: () => {
                    this.updateTabIndexes(slides);
                },
                change: () => {
                    this.selectedIndex = this.slider.selectedIndex;
                },
            },
        };

        if (this.autoplay && this.autoplay > 0) {
            options.autoPlay = this.autoplay * 1000;
        }

        this.slider = new Flickity(this.sliderContainer, options);

        super.init();

        this.updateProgressBar();

        const slides = this.sliderContainer.querySelectorAll(".slide");

        slides.forEach((slide) => {
            this.totalWidth += slide.offsetWidth;
        });

        this.slideXTotal = this.totalWidth - this.sliderContainer.offsetWidth;

        window.addEventListener("resize", this.handleResize.bind(this));

        this.slider.on("change", (e) => {
            this.updateProgressBar();
        });
    }

    handleResize() {
        this.isMobile = window.innerWidth <= 768;

        if (this.isMobile) {
            this.destroySlider();
        } else {
            this.reinitializeSlider();
        }

        this.totalWidth = 0;
        const slides = this.sliderContainer.querySelectorAll(".slide");
        slides.forEach((slide) => {
            this.totalWidth += slide.offsetWidth;
        });

        this.slideXTotal = this.totalWidth - this.sliderContainer.offsetWidth;

        this.updateProgressBar();
    }

    destroySlider() {
        if (this.slider) {
            this.slider.destroy();
            this.slider = null;
        }
    }

    reinitializeSlider() {
        if (!this.slider) {
            this.init();
        }
    }

    updateProgressBar() {
        const progressBar = this.$refs.progressBar;
        if(!progressBar) return;
        const progress = Math.abs(this.slider.x) / this.slideXTotal * 100;
        progressBar.style.width = progress + "%";
    }
}

document.addEventListener("alpine:init", () => {
    Alpine.data("postGridSlider", (autoplay, infinite) => new PostGridSlider(autoplay, infinite));
});
