window.phoneField = () => {
    const phoneField = document.querySelectorAll('.wpforms-field-phone');

    if(typeof phoneField === 'undefined' || !phoneField.length) return;

    function updatePhoneField(field, value = 'GB') {
        const flagContainer = field.querySelector('.iti__selected-flag');

        flagContainer.querySelector('.iti__flag').textContent = value;
    }

    phoneField.forEach((field) => {
        const flagInput = field.querySelector('.iti__flag-container');

        if(typeof flagInput === 'undefined' || !flagInput) return;

        let currentSelection = 'GB';

        updatePhoneField(field, currentSelection);

        const inputOptions = flagInput.querySelectorAll('.iti__country-list>li');

        inputOptions.forEach((option) => {
            option.addEventListener('click', () => {
                currentSelection = option.getAttribute('data-country-code').toUpperCase();
                updatePhoneField(field, currentSelection);
            });
        });
    });
}

window.onload = () => {
    window.phoneField();
}