import Flickity from '../../node_modules/flickity';

document.addEventListener('alpine:init', () => {
    Alpine.data('testimonials', () => ({
        slider: null,
        sliderContainer: null,
        sliderEnd: false,
        init() {
            this.sliderContainer = this.$refs.slider;
            const slides = this.sliderContainer.querySelectorAll('.Testimonials__Slider-slide');

            this.slider = new Flickity(this.sliderContainer, {
                cellAlign: 'center',
                contain: true,
                wrapAround: true,
                prevNextButtons: false,
                pageDots: false,
                draggable: true,
                on: {
                    settle: () => {
                        this.updateTabIndexes(slides);
                    },
                    ready: () => {
                        window.dispatchEvent(new Event('resize'));
                        this.sliderResize();
                    }
                }
            });

            this.slider.on('change', () => {
                this.updateTabIndexes(slides);
                this.updateSliderEnd();
            });

            pegasus.onResizeFinished(() => {
                this.sliderResize();
            }, false);


            this.updateTabIndexes(slides);
            this.updateSliderEnd();
        },

        sliderResize() {
            const flickitySliders = document.querySelectorAll('.Content__Card__Slider-slider .flickity-slider');
            
            this.sliderFullWidth = 0;
            
            flickitySliders.forEach(slider => {
                const slides = slider.querySelectorAll('.Content__Slider-slide');
                let height = 0;

                slides.forEach(slide => {
                    slide.style.height = '';
                    this.sliderFullWidth += slide.offsetWidth;
                    if(slide.offsetHeight > height){
                        height = slide.offsetHeight;
                    }
                });

                slides.forEach(slide => {
                    slide.style.height = height+'px';
                })

                slider.style.height = '';
                slider.style.height = height+'px';

            });

        },


        /**
         * updateTabIndexes
         * 
         * Description: Updates the tabindexes of the slides this is required for accessibility.
         * Inactive slides shouldn't be accessible via the keyboard.
         * 
         * @param {} slides 
         */
        updateTabIndexes(slides) {
            slides.forEach(slide => {
                const slideElements = slide.querySelectorAll('a, button');

                slideElements.forEach(element => {
                    if(slide.classList.contains('is-selected')) {
                        element.removeAttribute('tabindex');
                    } else {
                        element.setAttribute('tabindex', '-1');
                    }
                });
            })
        },

        /**
         * updateSliderEnd
         * 
         * Description: Updates sliderEnd property based on whether the slider is at the end or not.
         */
        updateSliderEnd() {
            const selectedIndex = this.slider.selectedIndex;
            const slideCount = this.slider.slides.length;
            this.sliderEnd = selectedIndex === slideCount - 1;
        },
    }))
});
