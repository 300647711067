document.addEventListener('alpine:init', () => {
    Alpine.data('mediaBlock', () => ({
        videoType: 'upload',
        video: null,

        init() {
            this.video = this.$root.querySelector('video') || this.$root.querySelector('iframe');

            if(!this.video){
                return;
            }

            // Assign the embed video type if needed
            if(this.video.classList.contains('video-embed')){
                this.videoType = 'embed';
            }
        },

         /**
         * playVideo
         * 
         * Description: Hides the placeholder and plays the video.
         * 
         */
         playVideo() {

            // Standard HTML5 videos can simply use the play() and pause() methods.
            if(this.videoType === 'upload') {
                this.video.play();
                this.$refs.videoPlaceholder.classList.add('hidden');
                return;
            }

            // Embed videos need to update the autoplay parameter in the URL.
            if(this.videoType === 'embed') {
                const currentSrc = this.video.src;

                const currentSrcArray = currentSrc.split('&');

                let newSrc = '';

                currentSrcArray.forEach(part => {
                    if(part.includes('autoplay=')){
                        part = 'autoplay=1';
                    }
                    newSrc += part + '&';
                })

                this.video.src = newSrc;
            }

            this.$refs.videoPlaceholder.classList.add('hidden');

            
        },
    }))
});